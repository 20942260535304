import { ReservableSpaceScreen, SpaceScreenMapPinType } from 'components/organisms/Reservable'
import { SpaceScreenSeatPin } from 'components/organisms/Reservable/types'
import { getTimeInHours, getHoursFromISODateString } from 'helpers/utils'
import * as T from 'types/data.types'
import { EAvailabilityType, EReservableType, PIN_CIRCLE } from 'types/data.types'

import { checkPersonTeamAbilitySeat } from './conditions'
import { getEmployeesFromBookings } from '../containers/SpaceScreen/helpers'
import { BookingType } from '../types/common.types'

import { sortBookingsByStartTime } from './'

export const convertReservables = ({
    reservables,
    employeeId,
    employeeDepartmentIDs,
    suggestedSeatID,
    startFilterTime,
    endFilterTime,
    startSpaceWorkingTime,
    endSpaceWorkingTime,
    isAdmin,
    isRepeat = false,
    isOnlyFreeSelectable = false,
    isPersonalSeatAvailableToday = false,
    isTeamSeatAvailableToday = false,
    isForToday = false,
    bookingStatus,
}: {
    reservables: Array<T.SeatPickerReservable>
    employeeId: string
    employeeDepartmentIDs: T.MainEmployeeData['departmentIDs']
    suggestedSeatID: string | null
    startFilterTime: string
    endFilterTime: string
    startSpaceWorkingTime: string
    endSpaceWorkingTime: string
    isAdmin: boolean
    isRepeat?: boolean
    isOnlyFreeSelectable?: boolean
    isPersonalSeatAvailableToday?: boolean
    isTeamSeatAvailableToday?: boolean
    isForToday?: boolean
    bookingStatus?: BookingType
}) => {
    const convertedReservables: ReservableSpaceScreen[] = []

    const seatVariants = {
        bookedSeat: null as ReservableSpaceScreen | null,
        suggestedSeat: null as ReservableSpaceScreen | null,
        randomSeat: null as ReservableSpaceScreen | null,
    }

    const allBookings: T.PickerReservableBooking[] = []
    const bookingWithoutParking: T.PickerReservableBooking[] = []

    reservables.forEach((reservable) => {
        const {
            id,
            name,
            x,
            y,
            type,
            availabilityType,
            availabilityByDatesAndTime,
            availabilityPreviewByDates,
            zonePoints,
            color,
            BookingReservablesByCreatedAt,
            BookingsByDateAndDayOfWeek,
            Tags,
        } = reservable

        const bookings =
            type === EReservableType.PARKING && BookingReservablesByCreatedAt
                ? BookingReservablesByCreatedAt.items.map((item) => item.booking)
                : BookingsByDateAndDayOfWeek?.items || []

        const tags = Tags?.items.map((tag) => ({ name: tag?.Tag?.name, id: tag?.Tag?.id })) || []

        const isMyBooking = bookings.some((booking) => booking?.Employee?.id === employeeId)

        if (type !== EReservableType.PARKING) {
            bookingWithoutParking.push(...bookings)
        }

        allBookings.push(...bookings)

        const typeSeat = isRepeat
            ? getTypeRepeatSpaceMapPin({
                  reservable,
                  employeeId,
                  employeeDepartmentIDs,
                  isPersonalSeatAvailableToday,
                  isTeamSeatAvailableToday,
                  isForToday: false,
              })
            : getTypeSpaceScreenMapPin({
                  reservable,
                  bookings,
                  employeeId,
                  employeeDepartmentIDs,
                  startFilterTime,
                  endFilterTime,
                  isPersonalSeatAvailableToday,
                  isTeamSeatAvailableToday,
                  isForToday,
              })

        const noSelectable = (() => {
            let result = true
            if (isOnlyFreeSelectable) {
                result = !isMyBooking && typeSeat.pinType !== SpaceScreenMapPinType.FREE_FULL
            } else {
                const isBlockedOrRepeatFull =
                    typeSeat.pinType === SpaceScreenMapPinType.BLOCKED ||
                    typeSeat.pinType === SpaceScreenMapPinType.REPEAT_FULL
                const isUnavailableAndNotAdmin =
                    !isAdmin &&
                    (typeSeat.pinType === SpaceScreenMapPinType.UNAVAILABLE_FREE ||
                        typeSeat.pinType === SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED)

                result = isBlockedOrRepeatFull || isUnavailableAndNotAdmin
            }

            if (reservable.type !== EReservableType.ROOM) {
                if (
                    bookingStatus &&
                    bookingStatus === BookingType.TEAM_EVENT &&
                    reservable.type !== EReservableType.ZONE
                ) {
                    result = true
                } else {
                    result = !reservable.availabilityByDatesAndTime
                }
            }

            return result
        })()

        const convertedReservable: ReservableSpaceScreen = {
            name,
            availabilityType,
            id,
            x,
            y,
            typeReservable: type,
            typeSeat,
            bookings,
            nameRoom: name,
            preview: availabilityPreviewByDates,
            endSpaceWorkingTime,
            startSpaceWorkingTime,
            selectable: !noSelectable,
            tags,
            zonePoints,
            color,
            availabilityByDatesAndTime,
        }

        if (
            type === EReservableType.SEAT ||
            type === EReservableType.ROOM ||
            type === EReservableType.PARKING ||
            type === EReservableType.ZONE
        ) {
            convertedReservables.push(convertedReservable)
        }

        if (isMyBooking) {
            seatVariants.bookedSeat = convertedReservable
        }

        if (convertedReservable.id === suggestedSeatID) {
            seatVariants.suggestedSeat = convertedReservable
        }

        if (typeSeat.pinType === SpaceScreenMapPinType.FREE_FULL) {
            seatVariants.randomSeat = convertedReservable
        }
    })

    return {
        convertedReservables,
        suggestedConvertedSeat: seatVariants.bookedSeat || seatVariants.suggestedSeat || seatVariants.randomSeat,
        allBookings,
        bookingWithoutParking,
    }
}

const getTypeRepeatSpaceMapPin = ({
    reservable,
    employeeId,
    employeeDepartmentIDs,
    isPersonalSeatAvailableToday,
    isTeamSeatAvailableToday,
    isForToday,
}: {
    reservable: T.SeatPickerReservable
    employeeId: string
    employeeDepartmentIDs: T.MainEmployeeData['departmentIDs']
    isPersonalSeatAvailableToday: boolean
    isTeamSeatAvailableToday: boolean
    isForToday: boolean
}): SpaceScreenSeatPin => {
    const isBlocked = reservable.availabilityType === EAvailabilityType.UNAVALIABLE
    const isUnavailable = checkPersonTeamAbilitySeat(
        reservable,
        employeeId,
        employeeDepartmentIDs,
        isPersonalSeatAvailableToday,
        isTeamSeatAvailableToday,
        isForToday,
    )

    const bookings = reservable?.BookingsByDateAndDayOfWeek?.items || []
    const isFree = bookings.filter((booking) => booking.employeeID !== employeeId).length === 0

    let pinType

    switch (true) {
        case isBlocked:
            pinType = SpaceScreenMapPinType.BLOCKED
            break

        // @ts-ignore
        case isUnavailable && isFree:
            pinType = SpaceScreenMapPinType.UNAVAILABLE_FREE
            break

        // @ts-ignore
        case !isFree:
            pinType = SpaceScreenMapPinType.REPEAT_FULL
            break

        default:
            pinType = SpaceScreenMapPinType.FREE_FULL
            break
    }

    return {
        pinType,
        isOnlyBlockedTime: false,
    }
}

const getTypeSpaceScreenMapPin = ({
    reservable,
    bookings,
    employeeId,
    employeeDepartmentIDs,
    startFilterTime,
    endFilterTime,
    isPersonalSeatAvailableToday,
    isTeamSeatAvailableToday,
    isForToday,
}: {
    reservable: T.SeatPickerReservable
    bookings: Array<T.PickerReservableBooking>
    employeeId: string
    employeeDepartmentIDs: T.MainEmployeeData['departmentIDs']
    startFilterTime: string
    endFilterTime: string
    isPersonalSeatAvailableToday: boolean
    isTeamSeatAvailableToday: boolean
    isForToday: boolean
}): SpaceScreenSeatPin => {
    const isBlocked = reservable.availabilityType === EAvailabilityType.UNAVALIABLE
    const isUnavailable = checkPersonTeamAbilitySeat(
        reservable,
        employeeId,
        employeeDepartmentIDs,
        isPersonalSeatAvailableToday,
        isTeamSeatAvailableToday,
        isForToday,
    )

    let isFullDay = false
    if (reservable.type === EReservableType.SEAT || reservable.type === EReservableType.PARKING) {
        isFullDay = checkIsFullDayByBookings(bookings, startFilterTime, endFilterTime)
    } else if (reservable.type === EReservableType.ROOM) {
        isFullDay = checkIsFullDayRoomPreview(reservable.availabilityPreviewByDates)
    }

    const isMultiBooking = checkIsMultiBookingSpaceScreenPin(bookings)
    const isBooking = bookings.length > 0
    const isOnlyBlockedTime = Boolean(bookings.length === 1 && bookings[0].isBlocked)

    let pinType: SpaceScreenMapPinType

    if (isBlocked) {
        pinType = SpaceScreenMapPinType.BLOCKED
    } else if (isUnavailable && !isMultiBooking && !isFullDay) {
        pinType = SpaceScreenMapPinType.UNAVAILABLE_FREE
    } else if (isUnavailable && isMultiBooking && !isFullDay) {
        pinType = SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED
    } else if (isFullDay && isMultiBooking) {
        pinType = SpaceScreenMapPinType.MULTI_BOOKED_FULL
    } else if (!isFullDay && isMultiBooking) {
        pinType = SpaceScreenMapPinType.MULTI_BOOKED_PART
    } else if (isFullDay && !isMultiBooking) {
        pinType = SpaceScreenMapPinType.ONE_BOOKED_FULL
    } else if (!isFullDay && !isMultiBooking && isBooking) {
        pinType = SpaceScreenMapPinType.ONE_BOOKED_PART
    } else {
        pinType = SpaceScreenMapPinType.FREE_FULL
    }

    return {
        pinType,
        isOnlyBlockedTime,
    }
}

const checkIsFullDayRoomPreview = (previewHours?: PIN_CIRCLE[]): boolean => {
    return previewHours ? previewHours.every((hour) => hour === PIN_CIRCLE.FULL) : false
}

const checkIsFullDayByBookings = (
    bookings: Array<T.PickerReservableBooking>,
    startFilterTime: string,
    endFilterTime: string,
): boolean => {
    if (bookings.length === 0) {
        return false
    }

    const sortedBookings = sortBookingsByStartTime(bookings, true)
    let hasFullBooking = false

    // Convert filter times to numerical hours
    const filterStart = getTimeInHours(startFilterTime)
    const filterEnd = getTimeInHours(endFilterTime)

    // Initialize joined time with the first booking's start and end times
    const joinedTime = {
        startTime: getTimeInHours(getHoursFromISODateString(sortedBookings[0].startTime)),
        endTime: getTimeInHours(getHoursFromISODateString(sortedBookings[0].endTime)),
    }

    for (const booking of sortedBookings) {
        const { isFullDay, startTime, endTime } = booking

        if (isFullDay) {
            hasFullBooking = true
            break
        }

        const bookingStart = getTimeInHours(getHoursFromISODateString(startTime))
        const bookingEnd = getTimeInHours(getHoursFromISODateString(endTime))

        // Check if there is a gap between current booking and joined time
        if (bookingStart > joinedTime.endTime || bookingEnd < joinedTime.startTime) {
            break
        }

        // Extend the joined time
        joinedTime.startTime = Math.min(bookingStart, joinedTime.startTime)
        joinedTime.endTime = Math.max(bookingEnd, joinedTime.endTime)

        if (joinedTime.startTime <= filterStart && joinedTime.endTime >= filterEnd) {
            hasFullBooking = true
            break
        }
    }

    return hasFullBooking
}

const checkIsMultiBookingSpaceScreenPin = (bookings: Array<T.PickerReservableBooking>): boolean => {
    const employees = getEmployeesFromBookings(bookings)
    return employees.length > 1
}
