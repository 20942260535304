import { combineReducers, configureStore } from '@reduxjs/toolkit'

import calendarReducer from 'containers/UniversalCalendar/calendarSlice'

import loaderReducer from './slices/loader'
import messageReducer from './slices/message'
import userReducer from './slices/user'
import calendarScheduleReducer from '../containers/Calendar/slices/calendarSchedule'
import dashboardReducer from '../containers/Dashboard/slices/dashboard'
import mobileRoomsReducer from '../containers/MobileRooms/slices/mobileRooms'
import spaceScreenReducer from '../containers/SpaceScreen/slices/spaceScreen'

const rootReducer = combineReducers({
    calendar: calendarReducer,
    message: messageReducer,
    user: userReducer,
    loader: loaderReducer,
    spaceScreen: spaceScreenReducer,
    calendarSchedule: calendarScheduleReducer,
    mobileRooms: mobileRoomsReducer,
    dashboard: dashboardReducer,
})

export const store = configureStore({
    reducer: rootReducer,
    devTools: true,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
